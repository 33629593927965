$( document ).ready(function() {
  $('*[data-poload]').mouseenter(function() {
    var e=$(this);
    $.get(e.data('poload'),function(d) {
        e.popover({content: d}).popover('show');
    });
  }).mouseleave(function() {
      $(this).popover('hide');
  });

  bindQtip();

});

$(function () {
  $('[data-toggle="tooltip"]').tooltip()
  $('[data-toggle="popover"]').popover()
})

function setprogress(prog,barid) {
    var progval = prog+'%';
    var bar = "#"+barid;
    $(bar).css('width', progval);
}

//Function to redirect after AJAX call if user is logged out
$( document ).ajaxComplete(function( event, xhr, settings ) {

  if (xhr.status == 401) { //Code is Unauthorised
    //They are logged out, redirect them somewhere useful:
    window.location.replace("/../auth/login"); //Log in page
  }

});

//Pusher:
channel.bind('App\\Events\\ReceivedSMS', function(data) {
  notifysms(data.smsid);
});
channel.bind('App\\Events\\ReceivedCall', function(msg) {
  var clientid = msg.clientid;
  var notifhead = "Incoming call from "+clientid+" "+msg.clientname;
  var notiftext = msg.from+"<br><a href='/../client/"+clientid+"'>View client case.</a>";
  $.toast({
    heading: notifhead,
    text: notiftext,
    icon: 'info',
    bgColor: '#80d4ff',
    textColor: 'black',
    position: 'bottom-right',
    allowToastClose: true,
    stack: 5,
    showHideTransition: 'slide',
    hideAfter: 12000
  });
});
channel.bind('App\\Events\\ReceivedEmail', function(msg) {
  var clientid = msg.clientid;
  var notifhead = "Email received from "+clientid+" "+msg.clientname;
  var notiftext = "<b>"+msg.subject+"</b><br>'"+msg.content+"...'<br><a href='/../client/"+clientid+"'>View client case.</a>";
  $.toast({
    heading: notifhead,
    text: notiftext,
    icon: 'info',
    bgColor: '#80d4ff',
    textColor: 'black',
    position: 'bottom-right',
    allowToastClose: true,
    stack: 5,
    showHideTransition: 'slide',
    hideAfter: 12000
  });
});
channel.bind('App\\Events\\ReceivedNotif', function(data) {
  notifynotif(data.notifid);
});

//Function to notify of a new message
function notifysms(messageid) {
  //We have received a Pusher Notification that a message has been received:
  $.ajax({
    method: "POST",
    url: "/../getsmsdata",
    data: { smsid: messageid },
    dataType: "json"
  })
  .done(function( msg ) {
    //Message received!
    var clientid = msg.clientid;
    var notifhead = "New SMS received from "+clientid+" "+msg.clientname;
    var notiftext = "'<b>"+msg.content+"</b>' <br><a href='/../client/"+clientid+"'>View client case.</a>";
    $.toast({
      heading: notifhead,
      text: notiftext,
      icon: 'info',
      bgColor: '#80d4ff',
      textColor: 'black',
      position: 'bottom-right',
      allowToastClose: true,
      stack: 5,
      showHideTransition: 'slide',
      hideAfter: 6000
    });
  });
}

function notifynotif(notifid) {
  //We have received a Pusher Notification that a notif has been received:
  //Get details via AJAX, checking if it is actually for the logged in user
  $.ajax({
    method: "POST",
    url: "/../getnotifdata",
    data: { notifid: notifid },
    dataType: "json"
  })
  .done(function( msg ) {
    //Message received!
    if (msg.notifid != null) {
      var notifhead = "New notification "+msg.createdbyname;
      var notiftext = "'<b>"+msg.content+"</b>'";
      $.toast({
        heading: notifhead,
        text: notiftext,
        icon: 'info',
        bgColor: '#ffb366',
        textColor: 'black',
        position: 'bottom-right',
        allowToastClose: true,
        stack: 5,
        showHideTransition: 'slide',
        hideAfter: 10000
      });
      //Update bell:
      $("#notifbell").removeClass("fa-bell-o");
      $("#notifbell").addClass("fa-bell bellblue");

      reloadnotifs();

    }
  });
}

function getAddressFromPostcode(postcode) {
  $.ajax({
  method: "POST",
  url: "/../getpostcode",
  data: { postcode: postcode }
})
  .done(function( msg ) {
    $('#postcodeLookupResultsBox').html(msg);
    $('#postcodeLookupModal').modal('show');
  });
}

$("a[href='#top']").click(function() {
  $("html, body").animate({ scrollTop: 0 }, "slow");
  return false;
});

function bindQtip() {
  $('[data-url]').qtip({
      content: {
          text: function(event, api) {
              $.ajax({
                  url: $(this).data('url') // Use data-url attribute for the URL
              })
              .then(function(content) {
                  // Set the tooltip content upon successful retrieval
                  api.set('content.text', content);
              }, function(xhr, status, error) {
                  // Upon failure... set the tooltip content to the status and error value
                  api.set('content.text', status + ': ' + error);
              });

              return '<i class="fa fa-spinner fa-spin"></i>'; // Set some initial text
          }
      }
  });
}

//navSearchThrobber
$('#navbarSearchBox, #navbarSearchBoxClient').autocomplete({
    serviceUrl: '/../client-partial-search',
    type: 'POST',
    params: {navbar: true},
    noSuggestionNotice: "No results",
    onSearchStart: function (query) {
      $("#navSearchThrobber").addClass('fa-spinner');
    },
    onSearchComplete: function (query) {
      $("#navSearchThrobber").removeClass('fa-spinner');
    },
    formatResult: function (suggestion, currentValue) {

      if (suggestion.data == "") {
        return suggestion.value;
      } else {
        return '<a style="cursor:pointer">' + suggestion.data + ' ' + suggestion.value + '</a>';
      }

    },
    onSelect: function (suggestion) {

        if (suggestion.data != "") {
          var url = "/../client/"+suggestion.data;
          window.location.href = url;
        }

    }
});

function Wilq32_addParameter(url, param, value, replace)
{
    if ((url == null) || (url.length == 0)) url = document.location.href;
    var regexp = new RegExp("(\\?|\\&)" + param + "\\=([^\\&]*)(\\&|$)");
    if (regexp.test(url) && replace)
        return (url.toString().replace(regexp,
					function(a, b, c, d)
			        {
			            return (b + param + "=" + value + d);
			        }));
    else
        return url + (/\?/.test(url) ? "&" : "?") + param + "=" + value;
}

function getUrlParameter(sParam) {
    var sPageURL = decodeURIComponent(window.location.search.substring(1)),
        sURLVariables = sPageURL.split('&'),
        sParameterName,
        i;

    for (i = 0; i < sURLVariables.length; i++) {
        sParameterName = sURLVariables[i].split('=');

        if (sParameterName[0] === sParam) {
            return sParameterName[1] === undefined ? true : sParameterName[1];
        }
    }
};

function basictoast(content) {
  $.toast({
    text: content,
    position: 'bottom-right',
    allowToastClose: true,
    stack: 4
  });
}

//Function to Edit tasks
$(".taskdesc").click(function() {
  var taskid = $(this).data("taskid");
  var curdesc = $(this).data("taskdesc");

  var tablecell = "#taskDescCell"+taskid;

  $(tablecell).html("<input type='text' class='form-control taskdesceditbox' id='taskdesceditbox"+taskid+"' data-taskid='"+taskid+"' value='"+curdesc+"'>");
  $(".taskdesceditbox").focus();
});

$("#tasktable").on("keyup", ".taskdesceditbox", function(e){
    var code = e.which; // recommended to use e.which, it's normalized across browsers
    if(code==13)e.preventDefault();
    if(code==13){

        var taskid = $("#"+e.target.id).data('taskid');
        var newdesc = $("#"+e.target.id).val();

        //Now perform our AJAX request to Save:
        $.ajax({
          method: "POST",
          url: "/../tasks/renametask",
          data: { taskid: taskid, taskdesc: newdesc }
        })
        .done(function( msg ) { //Done!

          //Replace
          var tablecell = "#taskDescCell"+taskid;
          $(tablecell).html(newdesc);

          //Now indicate that save was successful
          $.toast({
            heading: "Saved",
            text: "Saved task description.",
            position: 'bottom-right',
            allowToastClose: false,
            showHideTransition: 'slide'
          });

        }); //End AJAX function

    }
});

//Function to Edit Assigned User for tasks
$(".taskassigned").click(function() {
  var taskid = $(this).data("taskid");
  var curassigned = $(this).data("taskassigned");

  var tablecell = "#taskAssignedCell"+taskid;

  //We should fetch replacement HTML from an AJAX get request:
  $.ajax({
    method: "GET",
    url: "/../tasks/getuserpicker",
    data: {'currentuser': curassigned, 'taskid': taskid}
  })
  .done(function( msg ) { //Done!

    //Replace
    $(tablecell).html(msg);

  }); //End AJAX function

});

//When assignee selector is clicked:
$("#tasktable").on("change", ".taskassignedselect", function(e){

        var taskid = $("#"+e.target.id).data('taskid');
        var newassigned = $("#"+e.target.id).val();
        var newassignedname = $("#"+e.target.id+" option:selected" ).text();

        //Now perform our AJAX request to Save:
        $.ajax({
          method: "POST",
          url: "/../tasks/assigntask",
          data: { taskid: taskid, assigned: newassigned, newassignedname: newassignedname }
        })
        .done(function( msg ) { //Done!

          //Replace
          var tablecell = "#taskAssignedCell"+taskid;
          $(tablecell).html(msg);

          //Now indicate that save was successful
          $.toast({
            heading: "Saved",
            text: "Assigned user saved.",
            position: 'bottom-right',
            allowToastClose: false,
            showHideTransition: 'slide'
          });

        }); //End AJAX function

});
